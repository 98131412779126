<template>
  <v-card
    v-bind="$attrs"
    :class="classes"
    class="pa-3"
    style="margin-top: 50px"
  >
    <div class="d-flex grow flex-wrap logi">
      <div
        :max-height="icon ? 90 : undefined"
        :width="icon ? 'auto' : '100%'"
        class="text-start v-card--material__heading mb-0"
      >
        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="$slots.image" name="image" />

        <div
          v-else-if="title && !icon"
          class="display-1 font-weight-light"
          v-text="title"
        />

        <v-img :src="icon" contain width="120" />

        <div v-if="text" class="headline font-weight-thin" v-text="text" />
      </div>

      <div v-if="$slots['after-heading']" class="ml-6">
        <slot name="after-heading" />
      </div>

      <div v-else-if="icon && title" class="ml-4">
        <div class="font-weight-light" v-text="title" />
      </div>
    </div>

    <div>
      <p>&nbsp;</p>
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "J3Card",

  props: {
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
};
</script>

<style lang="sass">
.logi
  position: absolute
  top: -60px


.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative

    transition: .3s ease
    z-index: 1
</style>
